header {
    background-color: #007bff;
    color: #fff;
    padding: 20px;
    text-align: center;
}
main {
    max-width: 800px;
    margin: 20px auto;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
header h1, header h2 {
    color: #007bff;
}
h1 {
    font-size: 2em;
}
h2 {
    font-size: 1.5em;
    margin-top: 30px;
}
p, ul {
    margin: 10px 0;
}
ul {
    padding-left: 20px;
}
a {
    color: #007bff;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}