.logo{
    margin-top: 15px;
    margin-left: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction:row;
    flex-wrap: wrap;
    float:left;
    
}
@media screen and (min-width:420px){
    logo {display:flex
    }
    logo{flex-direction: column;}
}

/* .logo-1{
    display: flex;
    
    float:left
    
}

.logo-2{
    display:flex;
    float:left
    
} */